/* BROWSER POLYFILLS */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int.js';
import 'core-js/es/parse-float.js';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/reflect';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/***************************************************************************************************
* Zone JS is required by Angular itself.
*/
import 'zone.js/dist/zone';  // Included with Angular CLI.

/***************************************************************************************************
* APPLICATION IMPORTS
*/

if (typeof SVGElement.prototype.contains == 'undefined') {
  SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}